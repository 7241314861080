import { MonetaryAmount } from './MonetaryAmount';

type PaymentStatus =
  | 'OPEN'
  | 'CANCELED'
  | 'PENDING'
  | 'AUTHORIZED'
  | 'EXPIRED'
  | 'FAILED'
  | 'PAID'
  | 'NOT_REQUIRED'
  | 'ORDER_CANCELED';

interface EventInfo {
  id: number;
  time?: string; // datetime
  testcenterName?: string;
  testarea?: string;
  delete: boolean;
}

interface UserInfo {
  id: number;
  username?: string;
  firstname?: string;
  lastname?: string;
  delete: boolean;
}

export interface EventBookingHistoryEvents {
  id: string; // UUID
  historyKey:
    | 'BOOKING_CREATED'
    | 'PAYMENT_STARTED'
    | 'PAYMENT_STATUS_CHANGED'
    | 'BOOKING_CANCELED'
    | 'FILE_UPLOADED'
    | 'EVENT_CHANGED';
  timestamp: string; // datetime
  priorPaymentStatus?: PaymentStatus;
  newPaymentStatus?: PaymentStatus;
  refundedAmount?: MonetaryAmount;
  fileName?: string;
  oldEvent?: EventInfo;
  newEvent?: EventInfo;
  modifyingUser?: UserInfo;
  comment?: string;
}
