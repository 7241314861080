<div class="content-container">
  <h1>
    {{ 'message.login-welcome.' + tenant | translate }}
  </h1>

  <mat-card>
    <p>
      {{ 'message.login-headline.' + tenant | translate }}
    </p>

    <ng-container [ngSwitch]="tenant">
      <div *ngSwitchCase="'default'">
        <div class="logos-container flex-row flex-center">
          <div class="logo-container flex-no-shrink">
            <img
              src="/assets/img/logos/PhaST-Logo.png"
              [alt]="'phast.name' | translate"
            />
          </div>
          <div class="logo-container flex-no-shrink">
            <img
              src="/assets/img/logos/leuphana_logo.svg"
              [alt]="'gsat.name' | translate"
            />
          </div>
        </div>
        <div class="logos-container flex-row flex-center">
          <div class="logo-container flex-no-shrink">
            <img
              src="/assets/img/logos/Wort-Bildmarke-ITB-Business.png"
              [alt]="'itb-business.name' | translate"
            />
          </div>
          <div class="logo-container flex-no-shrink">
            <img
              src="/assets/img/logos/Wort-Bildmarke-TM-WISO.png"
              [alt]="'tm-wiso.name' | translate"
            />
          </div>
        </div>
      </div>
      <div
        class="logos-container flex-row flex-center"
        *ngSwitchCase="'sdv'"
      >
        <div class="logo-container flex-no-shrink">
          <img
            src="/assets/img/logos/sdv-logo-sw.png"
            [alt]="'title.sdv.logo' | translate"
          />
        </div>
      </div>
    </ng-container>
    <div class="form-container flex-column">
      <button
        (click)="login()"
        mat-raised-button
        color="accent"
      >
        {{ 'label.login' | translate }}
      </button>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="button-spacer or-divider">
        <mat-divider fxFlex="1 0"></mat-divider>
        <div>{{ 'label.or' | translate }}</div>
        <mat-divider fxFlex="1 0"></mat-divider>
      </div>

      <ng-container *ngIf="enableMbr">
        <button
          (click)="login()"
          mat-stroked-button
          color="primary"
          class="mbr-login-button"
        >
          <img src="/assets/img/mbr/Logo-Quadrat-NBP_web.jpg" alt="" role="presentation" />
          {{ 'label.login-mbr' | translate }}
        </button>

        <div fxLayout="row" fxLayoutAlign="space-between center" class="button-spacer or-divider">
          <mat-divider fxFlex="1 0"></mat-divider>
          <div>{{ 'label.or' | translate }}</div>
          <mat-divider fxFlex="1 0"></mat-divider>
        </div>
      </ng-container>

      <button
        (click)="inviteDialog()"
        mat-stroked-button
        color="accent"
      >
        {{ 'label.register' | translate }}
      </button>
    </div>
    <div class="password-link-container">
      <a
        (click)="
          $event.preventDefault(); forgotPasswordDialog()
        "
        href=""
      >
        {{ 'label.forgot-password' | translate }}
      </a>
    </div>
  </mat-card>
</div>
