<mat-label [id]="labelId">
  <span *ngIf="hasLabel">
    {{ label | translate }}
  </span>
  <ng-content></ng-content>
  <em *ngIf="hasHint">
    <mat-hint>{{
      hint | translate
    }}</mat-hint>
  </em>
</mat-label>

<mat-radio-group
  [class]="radioGroupClass"
  (change)="change.emit($event)"
  [formControl]="field"
  [attr.aria-labelledby]="labelId"
  [color]="color"
>
  <mat-radio-button
    *ngFor="let option of options"
    [value]="option.value"
    >{{ option.label | translate }}</mat-radio-button
  >
</mat-radio-group>
